import { InscriptionPartiePrenanteDto } from '@shared/src/api/inscription-partie-prenante/dto/inscription-partie-prenante.dto';
import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { EXPERTS_GLOBAL_ID } from '@shared/src/constant/global-constants';
import { StatutInscription } from '@shared/src/enum/inscription-partie-prenante.enum';
import { StatutParcours } from '@shared/src/enum/parcours-partie-prenante.enum';
import { useAnsweredSujets } from '@shared/src/hooks/useAnsweredSujets';
import { useSujets } from '@shared/src/hooks/useSujets';
import { inscriptionService } from '@shared/src/services/InscriptionService';
import { Navigate, useParams } from 'react-router-dom';

export type ParcoursPageProps = {
  inscription: InscriptionPartiePrenanteDto | null;
  parcours: ParcoursPartiePrenanteDto | null;
};

// TODO : à découper en 2 !!!
/**
 * This page is used to resolve the parcours for
 * this user and redirect to the correct state of parcours
 */
export function ParcoursPage(props: ParcoursPageProps) {
  const { inscription, parcours } = props;
  const { organisationId, projetId, partiePrenanteId } = useParams();
  const { sujets } = useSujets(props.parcours?.id);
  const { answeredSujets, isAnsweredSujetsLoading } = useAnsweredSujets(props.parcours?.id);

  if (isAnsweredSujetsLoading) {
    return null;
  }

  if (inscription && projetId && sujets) {
    switch (inscription.statut) {
      case StatutInscription.INVITE:
        inscriptionService.update(inscription.id, { statut: StatutInscription.INSCRIT });
        return (
          <Navigate to={`/${organisationId}/projets/${projetId}/stakeholders/listing/${partiePrenanteId}/start`} />
        );

      case StatutInscription.INSCRIT:
        return (
          <Navigate to={`/${organisationId}/projets/${projetId}/stakeholders/listing/${partiePrenanteId}/start`} />
        );
    }
  }

  if (parcours && projetId && sujets) {
    switch (parcours.statut) {
      case StatutParcours.DEMARRE:
        if (!answeredSujets) {
          return (
            <Navigate to={`/${organisationId}/projets/${projetId}/stakeholders/listing/${partiePrenanteId}/sujet/1`} />
          );
        } else {
          if (answeredSujets.length < sujets.length) {
            return (
              <Navigate
                to={
                  `/${organisationId}/projets/${projetId}/stakeholders/listing/${partiePrenanteId}/sujet/` +
                  (answeredSujets.length + 1)
                }
              />
            );
          } else {
            if (!parcours?.genre) {
              return (
                <Navigate
                  to={`/${organisationId}/projets/${projetId}/stakeholders/listing/${partiePrenanteId}/profiling`}
                />
              );
            } else {
              if (EXPERTS_GLOBAL_ID.includes(parcours.__categoriePartiePrenante__.globalId ?? '')) {
                return (
                  <Navigate
                    to={`/${organisationId}/projets/${projetId}/stakeholders/listing/${partiePrenanteId}/last-question`}
                  />
                );
              } else {
                return (
                  <Navigate
                    to={`/${organisationId}/projets/${projetId}/stakeholders/listing/${partiePrenanteId}/end`}
                  />
                );
              }
            }
          }
        }
      case StatutParcours.TERMINE:
        return <Navigate to={`/${organisationId}/projets/${projetId}/stakeholders/listing/${partiePrenanteId}/end`} />;

      default:
        return null;
    }
  }
  return null;
}
