import AddIcon from '@mui/icons-material/Add';
import EmailIcon from '@mui/icons-material/Email';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { CategoriePartiePrenanteDto } from '@shared/src/api/categorie-partie-prenante/dto/categorie-partie-prenante.dto';
import { CreateUtilisateurDto } from '@shared/src/api/utilisateurs/dto/create-utilisateur.dto';
import { StatutInscription } from '@shared/src/enum/inscription-partie-prenante.enum';
import { StatutParcours } from '@shared/src/enum/parcours-partie-prenante.enum';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { useCategoriesPartiesPrenantes } from '@shared/src/hooks/useCategoriesPartiesPrenantes';
import { utilisateursService } from '@shared/src/services/UtilisateursService';
import { Langue } from '@shared/src/type/langue';
import * as Papa from 'papaparse';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { PartiePrenanteCategory, PartiePrenanteEditionType } from '../StakeholdersListingTab';

export type CustomToolbarProps = {
  projetId?: string;
  handleAddClick: () => void;
  handleRemindPartiePrenantesClick?: () => void;
  handleInvitePartiePrenantesClick?: () => void;
  disableRemindButton?: boolean;
  disableInviteButton?: boolean;
  disableImportButton?: boolean;
  disableAddButton?: boolean;
  setDataFromImport?: (
    newRows: PartiePrenanteEditionType[],
    newListPartiePrenanteWithCategorie: PartiePrenanteCategory[],
  ) => void;
  setOpenErrorSnackbar: (value: boolean) => void;
  setErrorMessage: (message: string) => void;
  setOpenValidationSnackbar?: (value: boolean) => void;
  setValidationMessage?: (message: string) => void;
  setIsLoading: (value: boolean) => void;
};
const HEADER_USER_EMAIL = 'Email';
const HEADER_USER_LASTNAME = 'Nom';
const HEADER_USER_FIRSTNAME = 'Prénom';

type ParsedCsvRow = {
  email: string;
  nom: string;
  prenom: string;
  categories: string[];
};

export function CustomToolbar(props: CustomToolbarProps) {
  const {
    projetId,
    handleAddClick,
    handleRemindPartiePrenantesClick,
    handleInvitePartiePrenantesClick,
    disableRemindButton,
    disableInviteButton,
    disableImportButton,
    disableAddButton,
    setDataFromImport,
    setOpenErrorSnackbar,
    setOpenValidationSnackbar,
    setValidationMessage,
    setErrorMessage,
    setIsLoading,
  } = props;
  const { categoriesPartiesPrenantes, isLoading } = useCategoriesPartiesPrenantes(projetId);
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /**
   * Handles the csv file upload event.
   *
   * @param event - The React change event.
   */
  const handleCSVFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file: File | null = event.target.files ? event.target.files[0] : null;
    if (!file) {
      setIsLoading(false);
      setOpenErrorSnackbar(true);
      setErrorMessage(
        `${intl.formatMessage({ id: 'dataGrid.errorMessage.import.title' })} - ${intl.formatMessage({
          id: 'dataGrid.errorMessage.import.noFile',
        })}`,
      );
      return;
    }

    // parse csv file
    Papa.parse(file, {
      header: true,
      delimitersToGuess: [',', ';'],
      encoding: 'iso-8859-1',
      skipEmptyLines: true,
      complete: (res) => parseFile(res),
    });
  };

  /**
   * Parses the file and performs various validations before importing the data.
   * @param results - The parsed result of the file.
   */
  const parseFile = async (results: Papa.ParseResult<unknown>) => {
    // Check if createNewPP is defined
    if (createNewPP === undefined) {
      setIsLoading(false);
      setOpenErrorSnackbar(true);
      setErrorMessage(
        `${intl.formatMessage({ id: 'dataGrid.errorMessage.import.title' })} -
        ${intl.formatMessage({ id: 'dataGrid.errorMessage.import.noCreateFunction' })}`,
      );
      return;
    }

    // Check if categoriesPartiesPrenantes is defined
    if (!categoriesPartiesPrenantes) {
      setIsLoading(false);
      setOpenErrorSnackbar(true);
      setErrorMessage(
        `${intl.formatMessage({ id: 'dataGrid.errorMessage.import.title' })} -
        ${intl.formatMessage({ id: 'dataGrid.errorMessage.import.noCategory' })}`,
      );
      return;
    }

    // Check if projetId is defined
    if (!projetId) {
      setIsLoading(false);
      setOpenErrorSnackbar(true);
      setErrorMessage(
        `${intl.formatMessage({ id: 'dataGrid.errorMessage.import.title' })} -
        ${intl.formatMessage({ id: 'dataGrid.errorMessage.import.noProject' })}`,
      );
      return;
    }

    // Parse the rows from the data and filter out any invalid rows
    const rows = results?.data
      .map(function (row: any) {
        return {
          email: row[HEADER_USER_EMAIL],
          nom: row[HEADER_USER_LASTNAME],
          prenom: row[HEADER_USER_FIRSTNAME],
          categories: Object.keys(row)
            .filter(function (header) {
              return ![HEADER_USER_EMAIL, HEADER_USER_LASTNAME, HEADER_USER_FIRSTNAME].includes(header);
            })
            .filter(function (categorieName) {
              return (row[categorieName] || '').trim() === '1';
            }),
        };
      })
      .filter((d: ParsedCsvRow) => !!d) as ParsedCsvRow[];

    // Validate the parsed file and display any errors
    const parsedFileErrors = validateParsedFile(rows, categoriesPartiesPrenantes);
    if (parsedFileErrors.length) {
      setIsLoading(false);
      setErrorMessage(
        `${intl.formatMessage({ id: 'dataGrid.errorMessage.import.title' })} - ${parsedFileErrors.join(' - ')}`,
      );
      setOpenErrorSnackbar(true);
      return;
    }

    // Create new partie prenantes and partie prenante categories
    const newDataFromImport = await Promise.all(
      rows.map(async (row) => {
        const categorieInfos = row.categories.map((categorieName) => {
          const categorie = categoriesPartiesPrenantes.find(
            (cat) => cat.nom.fr === categorieName.trim() || cat.nom.en === categorieName.trim(),
          );
          if (!categorie) {
            setIsLoading(false);
            setErrorMessage(
              `${intl.formatMessage({ id: 'dataGrid.errorMessage.import.title' })} - ${intl.formatMessage({
                id: 'dataGrid.errorMessage.import.unknownCategory.partOne',
              })} ${categorieName}`,
            );
            setOpenErrorSnackbar(true);
            throw new Error(`Categorie ${categorieName} not found`);
          }
          return { id: categorie.id, nom: categorie.nom };
        });

        const partiePrenanteWithCategorie: PartiePrenanteEditionType = {
          id: 'temporary_id',
          email: row.email,
          nom: row.nom,
          prenom: row.prenom,
          inscriptionId: '',
          statutInscription: StatutInscription.EN_ATTENTE_INVITATION,
          parcours: categorieInfos.map(({ id, nom }) => ({
            id: '',
            statutParcours: StatutParcours.A_FAIRE,
            categorieId: id,
            categorieNom: nom as Langue,
          })),
          isNew: true,
        };

        const newPPCategory: PartiePrenanteCategory = {
          id: 'temporary_id',
          categories: [
            {
              id: categorieInfos[0].id,
              nom: categorieInfos[0].nom,
            },
          ],
        };

        return await createNewPP(partiePrenanteWithCategorie, newPPCategory);
      }),
    );

    // Update the data of the table (if setDataFromImport is provided)
    if (setDataFromImport && newDataFromImport?.length > 0) {
      const newRows: PartiePrenanteEditionType[] = [];
      const newListPartiePrenanteWithCategorie: PartiePrenanteCategory[] = [];
      newDataFromImport.forEach((data) => {
        if (data?.rows) {
          newRows.push(data.rows);
        }
        if (data?.ppByCategory) {
          newListPartiePrenanteWithCategorie.push(data.ppByCategory);
        }
      });
      setDataFromImport(newRows, newListPartiePrenanteWithCategorie);
      setIsLoading(false);
      if (setOpenValidationSnackbar && setValidationMessage && newRows.length > 0) {
        setOpenValidationSnackbar(true);
        setValidationMessage(
          `${intl.formatMessage({
            id: 'dataGrid.validationMessage.import.partOne',
          })} ${newRows.length.toString()} ${intl.formatMessage({
            id: 'dataGrid.validationMessage.import.partTwo',
          })}`,
        );
      }
    }
  };

  /**
   * Validates the parsed file data and returns an array of error messages.
   * @param rows - The parsed CSV rows.
   * @param categoriesPartiesPrenantes - The categories of stakeholders.
   * @returns An array of error messages.
   */
  const validateParsedFile = (rows: ParsedCsvRow[], categoriesPartiesPrenantes: CategoriePartiePrenanteDto[]) =>
    rows.reduce((errors: string[], row, index) => {
      if (!row.email) {
        errors.push(`${intl.formatMessage({ id: 'dataGrid.errorMessage.import.noEmail' })} ${index}`);
      }
      if (!row.nom) {
        errors.push(`${intl.formatMessage({ id: 'dataGrid.errorMessage.import.noLastname' })} ${index}`);
      }
      if (!row.prenom) {
        errors.push(`${intl.formatMessage({ id: 'dataGrid.errorMessage.import.noFirstname' })} ${index}`);
      }
      if (!row.categories.length) {
        errors.push(`${intl.formatMessage({ id: 'dataGrid.errorMessage.import.noCategory' })} ${index}`);
      }
      if (row.categories.length > 1) {
        errors.push(`${intl.formatMessage({ id: 'dataGrid.errorMessage.import.tooManyCategories' })} ${index}`);
      }
      const categoryExists = categoriesPartiesPrenantes.some(
        (categorie) =>
          categorie.nom['en'] === row.categories[0]?.trim() || categorie.nom['fr'] === row.categories[0]?.trim(),
      );
      if (!categoryExists) {
        errors.push(
          `${intl.formatMessage({ id: 'dataGrid.errorMessage.import.unknownCategory.partOne' })} ${
            row.categories[0]
          } ${intl.formatMessage({ id: 'dataGrid.errorMessage.import.unknownCategory.partTwo' })} ${index}`,
        );
      }
      return errors;
    }, []);

  /**
   * Creates a new PartiePrenante (Stakeholder) based on the provided updatedRow and newPPCategory.
   * If the user already exists, it updates the existing user. Otherwise, it creates a new user.
   *
   * @param updatedRow - The updated row containing the information of the PartiePrenante.
   * @param newPPCategory - The new PartiePrenante category.
   * @returns A Promise that resolves to an object containing the updated row and the PartiePrenante by category.
   */
  const createNewPP = async (updatedRow: PartiePrenanteEditionType, newPPCategory: PartiePrenanteCategory) => {
    const createUser: CreateUtilisateurDto = {
      nom: updatedRow.nom,
      prenom: updatedRow.prenom,
      email: updatedRow.email,
      role: UtilisateurRole.PARTIE_PRENANTE,
      categoriePartiePrenanteIds: updatedRow.parcours.map(({ categorieId }) => categorieId),
    };

    try {
      const response = await utilisateursService.findOneByEmail(createUser.email);
      if ('data' in response && response.data?.id) {
        // Edition d'un utilisateur existant
        updatedRow.id = response.data.id;
        const updateResponse = await utilisateursService.update(updatedRow.id, { ...createUser, projetId });
        if ('data' in updateResponse) {
          return {
            rows: updatedRow,
            ppByCategory: {
              id: updatedRow.id,
              categories: newPPCategory.categories,
            },
          };
        } else {
          // Throw an error if the request is not successful
          console.error(updateResponse);
          setOpenErrorSnackbar(true);
          setErrorMessage(
            `${intl.formatMessage({ id: 'dataGrid.errorMessage.import.title' })} -
            ${intl.formatMessage({ id: 'dataGrid.errorMessage.import.errorImportingUser' })} ${createUser.email}`,
          );
        }
      } else {
        const createResponse = await utilisateursService.create(createUser);
        if ('data' in createResponse && createResponse.data?.id) {
          updatedRow.id = createResponse.data.id;
          updatedRow.isNew = false;
          return {
            rows: updatedRow,
            ppByCategory: {
              id: updatedRow.id,
              categories: newPPCategory.categories,
            },
          };
        } else {
          // Throw an error if the request is not successful
          console.error(createResponse);
          setOpenErrorSnackbar(true);
          setErrorMessage(
            `${intl.formatMessage({ id: 'dataGrid.errorMessage.import.title' })} -
            ${intl.formatMessage({ id: 'dataGrid.errorMessage.import.errorImportingUser' })} ${createUser.email}`,
          );
        }
      }
    } catch (error) {
      console.error(error);
      setOpenErrorSnackbar(true);
      setErrorMessage(
        `${intl.formatMessage({ id: 'dataGrid.errorMessage.import.title' })} -
        ${intl.formatMessage({ id: 'dataGrid.errorMessage.import.errorImportingUser' })} ${createUser.email}`,
      );
    }
  };

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const handleImportPPClick = () => {
    setAnchorEl(null);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleExportExampleClick = () => {
    setAnchorEl(null);

    const Papa = require('papaparse');
    const csvHeaders: string[] = ['Email', 'Nom', 'Prénom'];
    if (!categoriesPartiesPrenantes || categoriesPartiesPrenantes.length === 0) {
      return null;
    }
    categoriesPartiesPrenantes.forEach((categorie) => {
      if (categorie.nom.fr) {
        csvHeaders.push(categorie.nom.fr);
      }
    });

    const csvString =
      '\ufeff' +
      Papa.unparse([csvHeaders], {
        quotes: true,
        delimiter: ';',
        header: true,
      });
    const csvData = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'import_parties_prenantes.csv');
    tempLink.click();
  };

  if (isLoading) {
    return null;
  }

  const open = Boolean(anchorEl);
  const handleOpenImportMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseImportMenu = () => {
    setAnchorEl(null);
  };

  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <Box sx={{ display: 'inline' }}>
        <GridToolbarColumnsButton style={{ maxWidth: 120, minWidth: 120, fontSize: '12px' }} />
        <GridToolbarFilterButton style={{ maxWidth: 120, minWidth: 120, fontSize: '12px' }} />
        <GridToolbarDensitySelector style={{ maxWidth: 120, minWidth: 120, fontSize: '12px' }} />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseImportMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleExportExampleClick}>
            <ListItemIcon>
              <FileDownloadIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>{intl.formatMessage({ id: 'dataGrid.toolbarImport.downloadExample' })}</ListItemText>
          </MenuItem>

          <MenuItem onClick={handleImportPPClick}>
            <ListItemIcon>
              <FileUploadIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>{intl.formatMessage({ id: 'dataGrid.toolbarImport.uploadFile' })}</ListItemText>
          </MenuItem>
        </Menu>
        {!disableImportButton && (
          <label htmlFor="contained-button-file">
            <input
              onChange={handleCSVFileUpload}
              style={{ display: 'none' }}
              accept=".csv"
              id="contained-button-file"
              type="file"
              ref={hiddenFileInput}
            />
            <Button
              style={{ maxWidth: 120, minWidth: 120, fontSize: '12px' }}
              variant="text"
              onClick={handleOpenImportMenu}
              startIcon={<ImportContactsIcon />}
            >
              {intl.formatMessage({ id: 'dataGrid.toolbarImport' })}
            </Button>
          </label>
        )}
        <GridToolbarExport style={{ maxWidth: 120, minWidth: 120, fontSize: '12px' }} />
      </Box>
      <Box>
        {!disableRemindButton && (
          <Tooltip title={intl.formatMessage({ id: 'dataGrid.button.remindEmail.tooltip' })}>
            <Button
              variant="contained"
              startIcon={<EmailIcon />}
              style={{
                maxWidth: 120,
                minWidth: 120,
                fontSize: '14px',
                marginRight: 10,
              }}
              onClick={handleRemindPartiePrenantesClick}
            >
              {intl.formatMessage({ id: 'dataGrid.button.remindEmail.label' })}
            </Button>
          </Tooltip>
        )}
        {!disableInviteButton && (
          <Tooltip title={intl.formatMessage({ id: 'dataGrid.button.firstEmail.tooltip' })}>
            <Button
              variant="contained"
              startIcon={<EmailIcon />}
              style={{
                maxWidth: 120,
                minWidth: 120,
                fontSize: '14px',
                marginRight: 10,
              }}
              onClick={handleInvitePartiePrenantesClick}
            >
              {intl.formatMessage({ id: 'dataGrid.button.firstEmail.label' })}
            </Button>
          </Tooltip>
        )}
        {!disableAddButton && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddClick}
            style={{
              maxWidth: 120,
              minWidth: 120,
              fontSize: '14px',
              marginRight: 10,
            }}
          >
            {intl.formatMessage({ id: 'dataGrid.addButton' })}
          </Button>
        )}
      </Box>
    </GridToolbarContainer>
  );
}
