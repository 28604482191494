import { Stack } from '@mui/material';
import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { SujetComponent } from '@shared/src/components/questionnaire/Content/SujetComponent';
import { OUTSIDEIN_CATEGORY_GLOBAL_IDS } from '@shared/src/constant/global-constants';
import { ImpactType } from '@shared/src/enum/sous-sujet.enum';
import { useSujets } from '@shared/src/hooks/useSujets';
import { useParams } from 'react-router-dom';

export type SujetPageProps = {
  parcours: ParcoursPartiePrenanteDto | null;
};

export function SujetPage(props: SujetPageProps) {
  const { projet } = useProjet();
  const { organisationId, projetId, partiePrenanteId } = useParams();
  const { sujets, isLoading } = useSujets(props.parcours?.id);
  if (!props.parcours) {
    return null;
  }

  if (isLoading || !sujets) {
    return null;
  }

  if (!projet) {
    return null;
  }

  return (
    <Stack sx={{ margin: 4 }}>
      <SujetComponent
        parcours={props.parcours}
        sujets={sujets}
        type={
          OUTSIDEIN_CATEGORY_GLOBAL_IDS.includes(props.parcours?.__categoriePartiePrenante__.globalId ?? '')
            ? ImpactType.OUTSIDEIN
            : ImpactType.INSIDEOUT
        }
        utilisateurId={partiePrenanteId}
        useSavedData={true}
        baseUrl={`/${organisationId}/projets/${projetId}/stakeholders/listing/${partiePrenanteId}`}
      />
    </Stack>
  );
}
