import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SaveIcon from '@mui/icons-material/Save';
import {
  Alert,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { CreateUtilisateurDto } from '@shared/src/api/utilisateurs/dto/create-utilisateur.dto';
import { UtilisateurPartiePrenanteDto } from '@shared/src/api/utilisateurs/dto/utilisateur.dto';
import { useLang } from '@shared/src/components/providers/LangProvider';
import { useProjet } from '@shared/src/components/providers/ProjetProvider';
import { StatutInscription } from '@shared/src/enum/inscription-partie-prenante.enum';
import { StatutParcours } from '@shared/src/enum/parcours-partie-prenante.enum';
import { StatutProjet } from '@shared/src/enum/projet.enum';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { useCategoriesPartiesPrenantes } from '@shared/src/hooks/useCategoriesPartiesPrenantes';
import { inscriptionService } from '@shared/src/services/InscriptionService';
import { mailerService } from '@shared/src/services/MailerService';
import { projetsService } from '@shared/src/services/ProjetsService';
import { utilisateursService } from '@shared/src/services/UtilisateursService';
import { Langue } from '@shared/src/type/langue';
import _isEqual from 'lodash-es/isEqual';
import _orderBy from 'lodash-es/orderBy';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import EmailAlert from './EmailAlert';
import FirstEmailAlert from './FirstEmailAlert';
import { CustomColumnMenu } from './Grid/CustomColumnMenu';
import { CustomGridLabels } from './Grid/CustomGridLabels';
import { CustomPagination } from './Grid/CustomPagination';
import { CustomToolbar } from './Grid/CustomToolbar';
import DeletionAlert from './Grid/DeletionAlert';
import { StakeholdersInscriptionStatus } from './StakeholdersInscriptionStatus';

export type PartiePrenanteCategory = {
  id: string;
  categories: {
    id: string;
    nom: Langue;
  }[];
};

export type ParcoursData = {
  id: string;
  categorieId: string;
  categorieNom: Langue;
  statutParcours: StatutParcours;
};

export type PartiePrenanteEditionType = {
  id: string;
  nom: string;
  prenom: string;
  email: string;
  inscriptionId: string;
  statutInscription: StatutInscription;
  parcours: ParcoursData[];
  isNew: boolean;
};

export function StakeholdersListingTab() {
  const { organisationId, projetId } = useParams();
  const { lang } = useLang();
  const { projet, reload } = useProjet();
  const { categoriesPartiesPrenantes } = useCategoriesPartiesPrenantes(projetId);
  const [rows, setRows] = useState<PartiePrenanteEditionType[]>([]);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openValidationSnackbar, setOpenValidationSnackbar] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string | null>(null);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [listPartiePrenanteWithCategorie, setListPartiePrenanteWithCategorie] = useState<PartiePrenanteCategory[]>([]);
  const [firstEmailAlertOpen, setFirstEmailAlertOpen] = useState<boolean>(false);
  const [emailAlertOpen, setEmailAlertOpen] = useState<{
    open: boolean;
    ppToEmail: { id: GridRowId | null; nom: string; prenom: string }[];
  }>({
    open: false,
    ppToEmail: [],
  });
  const [deleteAlertOpen, setDeleteAlertOpen] = useState<{
    open: boolean;
    ppToDelete?: { id: GridRowId | null; nom: string; prenom: string };
  }>({
    open: false,
    ppToDelete: {
      id: null,
      nom: '',
      prenom: '',
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const intl = useIntl();
  const navigation = useNavigate();

  useEffect(
    function () {
      if (!projetId) {
        setRows([]);
        return;
      }

      const abortController = new AbortController();

      utilisateursService
        .findUtilisateurByProjet({
          signal: abortController.signal,
        })
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          // The data are mixed up after SQL query : the original SQL order is lost.
          const ppList: UtilisateurPartiePrenanteDto[] = _orderBy(
            response.data,
            ['nom', 'prenom', 'inscriptionId', 'categoriePartiePrenanteNom'],
            ['desc', 'desc', 'desc', 'asc'],
          );
          const transformedList: PartiePrenanteEditionType[] = [];
          ppList.forEach(
            (
              {
                id,
                nom,
                prenom,
                email,
                inscriptionId,
                statutInscription,
                categoriePartiePrenanteId,
                categoriePartiePrenanteNom,
                parcoursId,
                statutParcours,
              },
              index,
            ) => {
              const newCategorie: ParcoursData = {
                id: parcoursId,
                categorieId: categoriePartiePrenanteId,
                categorieNom: categoriePartiePrenanteNom,
                statutParcours,
              };

              if (index && ppList[index - 1].id === id) {
                transformedList[0].parcours.push(newCategorie);
              } else {
                transformedList.unshift({
                  id,
                  nom,
                  prenom,
                  email,
                  inscriptionId,
                  statutInscription,
                  parcours: [newCategorie],
                  isNew: false,
                });
              }
            },
          );
          setRows(transformedList);

          setListPartiePrenanteWithCategorie(
            transformedList.map(({ id, parcours }) => ({
              id: id,
              categories: parcours.map(({ categorieId, categorieNom }) => ({ id: categorieId, nom: categorieNom })),
            })),
          );
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });

      return function () {
        abortController.abort();
      };
    },
    [projetId, reload],
  );

  const getCategoriesSelection = useCallback(
    (rowId: string) => {
      return listPartiePrenanteWithCategorie.find((el) => el.id === rowId)?.categories.map(({ id }) => id);
    },
    [listPartiePrenanteWithCategorie],
  );

  const onChangeCategory = (id: string, categoryIds: string | string[]) => {
    const newCategoryIds = typeof categoryIds === 'string' ? categoryIds.split(',') : categoryIds;
    const newCategories = listPartiePrenanteWithCategorie.map((el) => {
      if (el.id === id) {
        return {
          id: el.id,
          categories: newCategoryIds
            .filter((id) => !!id)
            .map((newCategoryId) => ({
              id: newCategoryId,
              nom: {
                en: categoriesPartiesPrenantes?.find((c) => c.id === newCategoryId)?.nom.en ?? null,
                fr: categoriesPartiesPrenantes?.find((c) => c.id === newCategoryId)?.nom.fr ?? null,
              },
            })),
        };
      } else {
        return el;
      }
    });
    setListPartiePrenanteWithCategorie(newCategories);
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleAnswerClick = (id: GridRowId) => () => {
    navigation(`/${organisationId}/projets/${projetId}/stakeholders/listing/${id}/parcours`);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const ppToDelete = rows.find((row) => row.id === id);
    if (ppToDelete) {
      const nom = ppToDelete.nom;
      const prenom = ppToDelete.prenom;
      setDeleteAlertOpen({ open: true, ppToDelete: { id, nom, prenom } });
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
      setListPartiePrenanteWithCategorie(listPartiePrenanteWithCategorie.filter((pp) => pp.id !== id));
    }
  };

  const handleAddClick = () => {
    if (rows.findIndex((el) => el.id === 'temporary_id') === -1) {
      const id = 'temporary_id';
      const newUser: PartiePrenanteEditionType = {
        id: id,
        nom: '',
        prenom: '',
        email: '',
        inscriptionId: '',
        statutInscription: StatutInscription.EN_ATTENTE_INVITATION,
        parcours: [
          {
            id: '',
            categorieId: '',
            categorieNom: {
              en: null,
              fr: null,
            },
            statutParcours: StatutParcours.A_FAIRE,
          },
        ],
        isNew: true,
      };

      setRows((oldRows) => [newUser, ...oldRows]);
      setListPartiePrenanteWithCategorie((oldList) => [
        { id: id, categories: [{ id: '', nom: { en: null, fr: '' } }] },
        ...oldList,
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nom' },
      }));
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const createNewPP = async (updatedRow: PartiePrenanteEditionType, newPPCategory?: PartiePrenanteCategory) => {
    const createUser: CreateUtilisateurDto = {
      nom: updatedRow.nom,
      prenom: updatedRow.prenom,
      email: updatedRow.email.trim(),
      role: UtilisateurRole.PARTIE_PRENANTE,
      categoriePartiePrenanteIds: updatedRow.parcours.map(({ categorieId }) => categorieId),
    };

    await utilisateursService.findOneByEmail(createUser.email).then((response) => {
      if ('data' in response && response.data?.id) {
        // Edition d'un utilisateur existant
        updatedRow.id = response.data.id;
        utilisateursService.update(updatedRow.id, { ...createUser, projetId }).then((response) => {
          if ('data' in response) {
            setRows(rows.map((row) => (row.id === 'temporary_id' ? updatedRow : row)));

            if (newPPCategory) {
              const newListPartiePrenanteWithCategorie: PartiePrenanteCategory[] = listPartiePrenanteWithCategorie.map(
                (pp) =>
                  pp.id === 'temporary_id'
                    ? {
                        id: updatedRow.id,
                        categories: newPPCategory.categories,
                      }
                    : pp,
              );
              setListPartiePrenanteWithCategorie(newListPartiePrenanteWithCategorie);
            }
          } else {
            // Throw an error if the request is not successful
            setRowModesModel({ ...rowModesModel, [updatedRow.id]: { mode: GridRowModes.Edit } });
            console.error(response);
            setOpenErrorSnackbar(true);
          }
        });
      } else {
        utilisateursService.create(createUser).then((response) => {
          if ('data' in response && response.data?.id) {
            updatedRow.id = response.data.id;
            updatedRow.isNew = false;

            // Update rows list
            setRows(rows.map((row) => (row.id === 'temporary_id' ? updatedRow : row)));

            // Update PP categories list
            if (newPPCategory) {
              const newListPartiePrenanteWithCategorie = listPartiePrenanteWithCategorie.map((pp) =>
                pp.id === 'temporary_id'
                  ? {
                      id: updatedRow.id,
                      categories: newPPCategory.categories,
                    }
                  : pp,
              );
              setListPartiePrenanteWithCategorie(newListPartiePrenanteWithCategorie);
            }
          } else {
            // Throw an error if the request is not successful
            const id = 'temporary_id';
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
            console.error(response);
            setOpenErrorSnackbar(true);
          }
        });
      }
    });
  };

  const updatePPCategory = (updatedRow: PartiePrenanteEditionType, newPPCategory: PartiePrenanteCategory) => {
    inscriptionService
      .update(updatedRow.inscriptionId, {
        categoriePartiePrenanteIds: newPPCategory?.categories.map(({ id }) => id),
      })
      .then(() => {
        const newListPartiePrenanteWithCategorie = listPartiePrenanteWithCategorie.map((pp) =>
          pp.id === updatedRow.id
            ? {
                id: pp.id,
                categories: newPPCategory.categories,
              }
            : pp,
        );

        setListPartiePrenanteWithCategorie(newListPartiePrenanteWithCategorie);
      });
  };

  const updatePPInfos = (updatedRow: PartiePrenanteEditionType) => {
    const editedRow = { ...updatedRow, email: updatedRow.email.trim() };
    utilisateursService.update(editedRow.id, editedRow).then((response) => {
      if ('data' in response) {
        setRows(rows.map((row) => (row.id === editedRow.id ? editedRow : row)));
      } else {
        // Throw an error if the request is not successful
        setRowModesModel({ ...rowModesModel, [editedRow.id]: { mode: GridRowModes.Edit } });
        console.error(response);
        setOpenErrorSnackbar(true);
      }
    });
  };

  const processRowUpdate = (newRow: PartiePrenanteEditionType) => {
    const newPPCategory: PartiePrenanteCategory | undefined = listPartiePrenanteWithCategorie.find(
      (el) => el.id === newRow.id,
    );

    // update ou creer parcours

    const emptyParcours: ParcoursData[] = (newPPCategory?.categories ?? []).map(({ id, nom }) => ({
      id: '',
      statutParcours: StatutParcours.A_FAIRE,
      categorieId: id,
      categorieNom: nom,
    }));

    const updatedRow: PartiePrenanteEditionType = {
      ...newRow,
      parcours: emptyParcours,
    };

    // if all user data are filled
    if (
      updatedRow.nom.length > 0 &&
      updatedRow.prenom.length > 0 &&
      updatedRow.email.length > 0 &&
      updatedRow.parcours.length > 0
    ) {
      if (updatedRow.isNew) {
        // if this is a new user
        createNewPP(updatedRow, newPPCategory);
      } else {
        const newPPCategoryIds = (newPPCategory?.categories ?? []).map(({ id }) => id);
        const newRowCategoryIds = newRow.parcours.map(({ categorieId }) => categorieId);
        const isCategoryChange = !_isEqual(newPPCategoryIds.sort(), newRowCategoryIds.sort());
        if (newPPCategory && isCategoryChange) {
          updatePPCategory(updatedRow, newPPCategory);
        }
        updatePPInfos(updatedRow);
      }
    } else {
      throw new Error('Wrong information about user');
    }
    return updatedRow;
  };

  const processRowDelete = (id: GridRowId) => {
    inscriptionService
      .removeByUserId(id.toString())
      .then((response) => {
        if ('statusCode' in response) {
          setOpenErrorSnackbar(true);
          return Promise.reject(response);
        }
        setRows(rows.filter((row) => row.id !== id));
      })
      .catch((e) => console.error(e));
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'nom',
      headerName: intl.formatMessage({ id: 'dataGrid.header.name' }),
      flex: 12,
      minWidth: 100,
      editable: true,
    },
    {
      field: 'prenom',
      headerName: intl.formatMessage({ id: 'dataGrid.header.surname' }),
      flex: 12,
      minWidth: 100,
      editable: true,
    },
    {
      field: 'email',
      headerName: intl.formatMessage({ id: 'dataGrid.header.email' }),
      type: 'email',
      flex: 35,
      minWidth: 300,
      editable: true,
    },
    {
      field: 'categoriePartiePrenanteIds',
      filterable: false,
      headerName: intl.formatMessage({ id: 'dataGrid.header.category' }),
      // valueFormatter: ({ value }) => {
      //   return categoriesPartiesPrenantes?.find((category) => category.id === value)?.nom[lang];
      // },
      renderCell: ({ value, row }) => {
        const typedRow = row as PartiePrenanteEditionType;
        return (
          <Stack direction="column" spacing={2} alignItems={'flex-start'}>
            {typedRow.parcours.map(({ categorieNom, categorieId }) => {
              return (
                <Typography key={categorieId} variant="body2">
                  {categorieNom[lang]}
                </Typography>
              );
            })}
          </Stack>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => (
        <Select
          labelId="categories-pp-select"
          value={getCategoriesSelection(params.row.id) as any}
          id={params.row.id}
          label="Category"
          fullWidth
          multiple={true}
          onChange={(e: SelectChangeEvent) => onChangeCategory(params.row.id, e.target.value)}
          renderValue={(selection) => (
            <Stack spacing={1}>
              {(selection as unknown as string[]).map((selectedId) => {
                categoriesPartiesPrenantes?.find(({ id }) => selectedId === id);
                return (
                  <Typography key={selectedId} variant="body2">
                    {categoriesPartiesPrenantes?.find(({ id }) => selectedId === id)?.nom[lang]}
                  </Typography>
                );
              })}
            </Stack>
          )}
        >
          {categoriesPartiesPrenantes?.map((category) => {
            return (
              <MenuItem value={category.id} id={category.id} key={category.id}>
                <Checkbox checked={getCategoriesSelection(params.row.id)?.includes(category.id)} />
                <ListItemText primary={category.nom[lang]} />
              </MenuItem>
            );
          })}
        </Select>
      ),
      flex: 25,
      minWidth: 200,
      editable: true,
    },
    {
      field: 'statutInscription',
      filterable: false,
      headerName: intl.formatMessage({ id: 'dataGrid.header.status' }),
      renderCell: ({ value, row }) => {
        return <StakeholdersInscriptionStatus statutInscription={value} parcours={row.parcours} />;
      },
      flex: 9,
      minWidth: 50,
      maxWidth: 120,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: intl.formatMessage({ id: 'dataGrid.header.actions' }),
      flex: 10,
      minWidth: 80,
      maxWidth: 120,
      cellClassName: 'actions',
      hideable: false,
      getActions: (params) => {
        if (projet?.statut === StatutProjet.CLOS) return [];

        if (params.row.statutInscription === StatutInscription.EN_ATTENTE_INVITATION) {
          const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <Tooltip title={intl.formatMessage({ id: 'dataGrid.saveButton' })}>
                <GridActionsCellItem
                  icon={<SaveIcon />}
                  label={intl.formatMessage({ id: 'dataGrid.saveButton' })}
                  sx={{
                    color: 'primary.main',
                  }}
                  onClick={handleSaveClick(params.id)}
                />
              </Tooltip>,
              <Tooltip title={intl.formatMessage({ id: 'dataGrid.cancelButton' })}>
                <GridActionsCellItem
                  icon={<CancelIcon />}
                  label={intl.formatMessage({ id: 'dataGrid.cancelButton' })}
                  className="textPrimary"
                  onClick={handleCancelClick(params.id)}
                  sx={{
                    color: 'primary.main',
                  }}
                />
              </Tooltip>,
            ];
          }

          return [
            <Tooltip title={intl.formatMessage({ id: 'dataGrid.editButton' })}>
              <GridActionsCellItem
                icon={<EditIcon />}
                label={intl.formatMessage({ id: 'dataGrid.editButton' })}
                className="textPrimary"
                onClick={handleEditClick(params.id)}
                sx={{
                  color: 'primary.main',
                }}
              />
            </Tooltip>,
            <Tooltip title={intl.formatMessage({ id: 'dataGrid.deleteButton' })}>
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label={intl.formatMessage({ id: 'dataGrid.deleteButton' })}
                onClick={handleDeleteClick(params.id)}
                sx={{
                  color: 'primary.main',
                }}
              />
            </Tooltip>,
          ];
        } else {
          return [
            <Tooltip title={intl.formatMessage({ id: 'dataGrid.answerButton' })}>
              <GridActionsCellItem
                icon={<RateReviewIcon />}
                label={intl.formatMessage({ id: 'dataGrid.answerButton' })}
                className="textPrimary"
                onClick={handleAnswerClick(params.id)}
                sx={{
                  color: 'primary.main',
                }}
              />
            </Tooltip>,
          ];
        }
      },
    },
  ];

  const invitePartiePrenantes = () => {
    if (projetId) {
      mailerService.invitePartiePrenantes().then((response) => {
        if ('statusCode' in response) {
          setErrorMessage(intl.formatMessage({ id: 'dataGrid.snackbar.email.error' }));
          setOpenErrorSnackbar(true);
        } else {
          projetsService.updateStatut(projetId, StatutProjet.DEMARRE).then((response) => {
            if ('statusCode' in response) {
              setErrorMessage(intl.formatMessage({ id: 'dataGrid.snackbar.email.error' }));
              setOpenErrorSnackbar(true);
            } else {
              setValidationMessage(intl.formatMessage({ id: 'dataGrid.snackbar.email.ok' }));
              setOpenValidationSnackbar(true);
              reload();
            }
          });
        }
      });
    }
  };

  const remindPartiePrenantes = () => {
    if (projetId) {
      mailerService.remindPartiePrenantes(JSON.stringify(rowSelectionModel)).then((response) => {
        if ('statusCode' in response) {
          setErrorMessage(intl.formatMessage({ id: 'dataGrid.snackbar.email.error' }));
          setOpenErrorSnackbar(true);
        } else {
          projetsService.updateStatut(projetId, StatutProjet.DEMARRE).then((response) => {
            if ('statusCode' in response) {
              setErrorMessage(intl.formatMessage({ id: 'dataGrid.snackbar.email.error' }));
              setOpenErrorSnackbar(true);
            } else {
              setRowSelectionModel([]);
              setValidationMessage(intl.formatMessage({ id: 'dataGrid.snackbar.email.ok' }));
              setOpenValidationSnackbar(true);
              reload();
            }
          });
        }
      });
    }
  };

  const handleRemindPartiePrenantesClick = () => {
    const ppToEmail: { id: string; nom: string; prenom: string }[] = [];
    if (rowSelectionModel.length > 0) {
      rowSelectionModel.forEach((rowId) => {
        const foundRow = rows.find((row) => row.id === rowId);
        if (foundRow) {
          ppToEmail.push({ id: foundRow.id, nom: foundRow.nom, prenom: foundRow.prenom });
        }
      });

      setEmailAlertOpen({ open: true, ppToEmail: ppToEmail });
    }
  };

  const handleInvitePartiePrenantesClick = () => {
    setFirstEmailAlertOpen(true);
  };

  const isNonInvitedPartiePrenante: () => boolean = () => {
    if (rows.length === 0) {
      return false;
    } else {
      const nonInvitedPartiePrenante = rows.filter(
        (row) => row.statutInscription === StatutInscription.EN_ATTENTE_INVITATION,
      );
      if (nonInvitedPartiePrenante.length === 1) {
        if (nonInvitedPartiePrenante[0].id === 'temporary_id') {
          return false;
        } else {
          return true;
        }
      }
      return nonInvitedPartiePrenante.length > 0;
    }
  };

  const setDataFromImport = (
    newRows: PartiePrenanteEditionType[],
    newListPartiePrenanteWithCategorie: PartiePrenanteCategory[],
  ) => {
    setRows([...rows, ...newRows]);
    setListPartiePrenanteWithCategorie([...listPartiePrenanteWithCategorie, ...newListPartiePrenanteWithCategorie]);
  };

  return (
    <Stack>
      <DeletionAlert
        alertOpen={deleteAlertOpen}
        setAlertOpen={setDeleteAlertOpen}
        deleteElement={processRowDelete}
        title={intl.formatMessage({ id: 'ppList.deletion.alert.title' })}
        contentPartOne={intl.formatMessage({ id: 'ppList.deletion.alert.content.partOne' })}
        contentPartTwo={intl.formatMessage({ id: 'ppList.deletion.alert.content.partTwo' })}
      />
      <EmailAlert
        changeStatut={projet?.statut === StatutProjet.CREE}
        alertOpen={emailAlertOpen}
        setAlertOpen={setEmailAlertOpen}
        remindPartiePrenantes={remindPartiePrenantes}
      />
      <FirstEmailAlert
        changeStatut={projet?.statut === StatutProjet.CREE}
        alertOpen={firstEmailAlertOpen}
        setAlertOpen={setFirstEmailAlertOpen}
        invitePartiePrenantes={invitePartiePrenantes}
      />
      <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={() => setOpenErrorSnackbar(false)}>
        <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage ?? intl.formatMessage({ id: 'dataGrid.snackbar.updateStakeholder.error' })}
        </Alert>
      </Snackbar>
      <Snackbar open={openValidationSnackbar} autoHideDuration={6000} onClose={() => setOpenValidationSnackbar(false)}>
        <Alert onClose={() => setOpenValidationSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {validationMessage ?? intl.formatMessage({ id: 'dataGrid.snackbar.updateStakeholder.ok' })}
        </Alert>
      </Snackbar>

      <DataGrid
        autoHeight
        getRowHeight={({ model }) => {
          const row = model as PartiePrenanteEditionType;
          return row.parcours.length * 40;
        }}
        sx={{ backgroundColor: 'white', borderColor: 'white', borderRadius: '1rem' }}
        rows={rows}
        columns={columns}
        editMode="row"
        checkboxSelection={projet?.statut !== StatutProjet.CLOS}
        onProcessRowUpdateError={() => setOpenErrorSnackbar(true)}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        loading={isLoading}
        rowSelectionModel={rowSelectionModel}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        // TODO in frontoffice PR
        // isRowSelectable={(params) => params.row.statutInscription !== StatutInscription.TERMINE}
        isCellEditable={(params) =>
          params.row.statutInscription === StatutInscription.EN_ATTENTE_INVITATION &&
          rowModesModel[params.id]?.mode === GridRowModes.Edit
        }
        slots={{
          toolbar: () =>
            CustomToolbar({
              projetId,
              handleAddClick,
              handleRemindPartiePrenantesClick,
              handleInvitePartiePrenantesClick,
              disableRemindButton:
                projet?.statut === StatutProjet.CLOS ||
                rowSelectionModel.length === 0 ||
                rowSelectionModel.includes('temporary_id'),
              disableInviteButton: projet?.statut === StatutProjet.CLOS || !isNonInvitedPartiePrenante(),
              disableImportButton: projet?.statut === StatutProjet.CLOS,
              disableAddButton: projet?.statut === StatutProjet.CLOS,
              setDataFromImport,
              setOpenErrorSnackbar,
              setErrorMessage,
              setOpenValidationSnackbar,
              setValidationMessage,
              setIsLoading,
            }),
          pagination: CustomPagination,
          columnMenu: CustomColumnMenu,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        pageSizeOptions={[5, 25, 50, 100]}
        localeText={CustomGridLabels()}
      />
    </Stack>
  );
}
