import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Stack, Typography } from '@mui/material';
import { InscriptionPartiePrenanteDto } from '@shared/src/api/inscription-partie-prenante/dto/inscription-partie-prenante.dto';
import { ParcoursPartiePrenanteDto } from '@shared/src/api/parcours-partie-prenante/dto/parcours-partie-prenante.dto';
import { UtilisateurPartiePrenanteDto } from '@shared/src/api/utilisateurs/dto/utilisateur.dto';
import { ProtectedRoute } from '@shared/src/components/ProtectedRoute';
import { Provider } from '@shared/src/components/providers/ProjetProvider';
import { UtilisateurRole } from '@shared/src/enum/utilisateur-roles.enum';
import { inscriptionService } from '@shared/src/services/InscriptionService';
import { parcoursService } from '@shared/src/services/ParcoursService';
import { utilisateursService } from '@shared/src/services/UtilisateursService';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { EndPage } from './EndPage';
import { ExplanationPage } from './ExplanationPage';
import { LastQuestionPage } from './LastQuestionPage';
import { ParcoursPage } from './ParcoursPage';
import { ProfilingPage } from './ProfilingPage';
import { SujetPage } from './SujetPage';

export function QuestionnaireAnswerAsRouter() {
  const { organisationId, projetId, partiePrenanteId } = useParams();
  const [partiePrenante, setPartiePrenante] = useState<UtilisateurPartiePrenanteDto | null>(null);
  const [inscription, setInscription] = useState<InscriptionPartiePrenanteDto | null>(null);
  const [parcours, setParcours] = useState<ParcoursPartiePrenanteDto | null>(null);
  const navigation = useNavigate();

  useEffect(() => {
    if (!partiePrenante && partiePrenanteId) {
      utilisateursService.findOneById(partiePrenanteId).then((response) => {
        if ('data' in response) {
          setPartiePrenante(response.data as unknown as UtilisateurPartiePrenanteDto);
        }
      });
    }
    if (projetId && partiePrenanteId) {
      parcoursService
        .findParcoursByProjetIdAndUserParam(projetId ?? '', partiePrenanteId ?? '')
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          setParcours(response.data ?? undefined);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
      inscriptionService
        .findInscriptionByProjetIdAndUserParam(projetId ?? '', partiePrenanteId ?? '')
        .then(function (response) {
          if ('statusCode' in response) {
            return Promise.reject(response);
          }
          setInscription(response.data ?? undefined);
        })
        .catch(function (error) {
          if (error.name === 'AbortError') return;
          console.error(error);
        });
    }
  }, [partiePrenante, partiePrenanteId, projetId]);

  return (
    <Provider projetId={projetId} organisationId={organisationId}>
      <Stack
        sx={{
          width: '100%',
          backgroundColor: 'secondary.dark',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0.5rem',
          borderRadius: '0.5rem',
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" marginRight="0.2rem">
            <FormattedMessage id="stakeholders.questionnaire.answer-as.banner.message" />
          </Typography>
          {partiePrenante && (
            <Typography variant="body2" fontWeight={800}>
              {partiePrenante.nom} {partiePrenante.prenom}
            </Typography>
          )}
        </Stack>
        <Button
          variant="text"
          endIcon={<ArrowForwardIcon />}
          sx={{ color: 'text.primary' }}
          onClick={() => navigation(`/${organisationId}/projets/${projetId}/stakeholders/listing`)}
        >
          <FormattedMessage id="stakeholders.questionnaire.answer-as.banner.goBackButton" />
        </Button>
      </Stack>
      <Routes>
        <Route element={<ProtectedRoute role={[UtilisateurRole.TENZING, UtilisateurRole.CLIENT]} />}>
          <Route path="/parcours" element={<ParcoursPage inscription={inscription} parcours={parcours} />} />
          <Route path="/start" element={<ExplanationPage parcours={parcours} />} />
          <Route path="/sujet/:position" element={<SujetPage parcours={parcours} />} />
          <Route path="/profiling" element={<ProfilingPage parcours={parcours} />} />
          <Route path="/last-question" element={<LastQuestionPage parcours={parcours} />} />
          <Route path="/end" Component={EndPage} />
        </Route>
      </Routes>
    </Provider>
  );
}
